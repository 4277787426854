<template>
  <div class="pressure">
    <div class="pressure-box">
      <div class="pressure-item">
        <div class="btns">
          <el-button type="primary" icon="el-icon-plus" size="small" @click="pressureAdd">新增</el-button>
        </div>
        <el-table
          :data="pressureTableData"
          :header-cell-style="{ background: '#f3f7fb' }"
          border
          height="calc(100vh - 300px)"
          style="width: 100%"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictCodes" align="center" label="属性名称">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.attributeName }}</div>
              <div v-else>
                <el-input v-model="row.attributeName" placeholder="请输入属性名称"></el-input>
              </div>
            </template>
          </el-table-column>
         

          <el-table-column align="center" width="250" label="操作">
            <template #default="{ row, $index }">
              <div v-if="row.id && !row.isEditor" class="tableBtn">
              <span class="btn" style="color: #1e9fff" @click="pressureEditor(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getDelete(row)">
                  删除
                </span>
                
              </div>
              <div v-else>
                <el-button
                  v-if="!row.id"
                  size="mini"
                  type="text"
                  style="margin-right: 20px"
                  @click="addSave(row, $index)"
                  >保存</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  type="text"
                  style="margin-right: 20px"
                  @click="update(row, $index)"
                  >保存</el-button
                >
                <el-button type="text" style="color:#000" size="mini" @click="pressureCancel(row, $index)">取消</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {attribute_delete,attribute_queryConfig,attribute_save,attribute_update} from '../apis/base'
export default {
  name: 'PressureLevel',
  data() {
    return {
      pressureTableData: [],
      copyRow: new Map()
     
    }
  },
  props: {
    showType: {
      type: String,
      default: ''
    }
  },
  computed: {
  },
  mounted() {
    this.getList()
  },
  methods: {
    
    
    async getList() {
      let res = await attribute_queryConfig({ pidDictCode:this.showType })
      this.pressureTableData = res.data
    },
    
    async addSave(row, index) {
      row.isEditor = false
      let res = await attribute_save(row)
      this.getList()
    },
    
    async update(row, index) {
      row.isEditor = false
      let res = await attribute_update(row)
      this.getList()
    },
    
    isEditor(row) {
      return row.id != '' && row.id != null && !row.isEditor
    },
    

   
    // 钢管新增
    pressureAdd() {
      let obj = {
        attributeName: '',
        id: '',
        pidDictCode:this.showType,
      }
      this.pressureTableData.push(obj)
    },

    // 钢管编辑
    async pressureEditor(row) {
       row.dictCodes = row.dictCode
      this.$set(row, 'isEditor', true)
      let data = JSON.parse(JSON.stringify(row))
      this.copyRow.set(data.id, data)
    },
    pressureCancel(row, index) {
      if (row.id) {
        let { copyRow } = this
        console.log(copyRow)
        let res = copyRow.get(row.id)
        console.log(res)
        row.attributeName = res.attributeName
        row.isEditor  = false
        
        this.copyRow.delete(row.id)
      } else {
        this.pressureTableData.splice(index, 1)
      }
    },
    
    async getDelete(row) {
      this.$confirm(`是否确认删除，删除后会删除已维护的管件属性？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        attribute_delete({ id: row.id }).then(res=>{
          this.getList()
        })
        
      });
      
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .el-table--border, .el-table--group{
//       border: 1px solid #cbd6e7}
// ::v-deep .el-table thead{
//   color: #000;
// }
// ::v-deep .el-table{
//   color: #636b70;
// }
.pressure-box {
  // display: flex;
  .btns {
    margin-bottom: 10px;
  }
  .pressure-item {
    // flex: 1;
    width: 600px;
  }
  .pepe-left {
    margin-right: 30px;
  }
  .tableBtn {
    .btn {
      cursor: pointer;
      margin: 0 12px;
      &.up {
        color: #1e9fff;
      }
      &.down {
        color: #0dba7b;
      }
    }
  }
}
</style>
