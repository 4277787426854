<template>
  <div class="setBase">
    <div>
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="管件属性" name="one">
          <tpl-add  v-if="activeName === 'one'" :showType="showType"></tpl-add>
        </el-tab-pane>
        <el-tab-pane label="管线点属性" name="two">
          <tpl-add  v-if="activeName === 'two'"  :showType="showType"></tpl-add>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import tplAdd from '../components/tplAdd.vue'
export default {
  components: {
    tplAdd
  },
  data() {
    return {
      activeName: 'one',
      showType:'11001'
    }
  },
  methods: {
    handleClick(tab, event) {
      if(this.activeName=='one'){
        this.showType = "11001"
      }else{
        this.showType = "11002"
      }
      // console.log(tab, event)
    }
  }
}
</script>

<style lang="scss" scoped>
.setBase {
  background: #fff;
  padding: 20px;
}
</style>
